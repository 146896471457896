import { useEffect, useState } from "react";
import "./App.css";
import { useSearchParams } from "react-router-dom";

function App() {
	const setDataObj = (type, value) => {
		setData({ ...data, [type]: value });
	};
	const [qs, setQs] = useState(null);
	useEffect(() => {
		if (!window) return;
		setQs(window.location.search.replace("?", "").split("=")[1]);
	}, [window]);

	const ambients = {
		opt1: {
			label: "Officina Auto e moto",
			level: [2],
			hands: 2,
			max: null,
		},
		opt2: {
			label: "Garage industriale",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt3: {
			label: "Garage casa",
			level: [2],
			hands: 1,
			max: null,
			colors: 0,
		},
		opt5: {
			label: "Cantina",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt6: {
			label: "Appartamento",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt6bis: {
			label: "Scale",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt7: {
			label: "Terrazza di casa, balcone (senza guaina)",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt8: {
			label: "Terrazza di casa, balcone (con guaina)",
			level: [2],
			hands: 1,
			max: null,
			colors: 0,
		},
		opt9: {
			label: "Terrazza di casa, balcone",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt11: {
			label: "Negozio di vendita al pubblico",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt12: {
			label: "Ristorante bar",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt13: {
			label: "Deposito industriale",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt14: {
			label: "Carrozzeria",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
		opt15: {
			label: "Altro",
			level: [2],
			hands: 2,
			max: null,
			colors: 1,
		},
	};
	const colors = {
		43307898142877: "Grigio cemento",
		47937997013339: "Bianco",
		47937996947803: "Grigio antracite",
		47937996980571: "Avorio",
		47937997046107: "Grigio chiaro",
		48086280962395: "Beige",
		47937997078875: "Tortora",
		47990832726363: "Rosso Ferrari",
		47990832693595: "Verde Prato",
		47990832759131: "Blu Elettrico",
		47937996915035: "Nero",
		46739454722395: "Per altri colori contattaci",
	};
	const [data, setData] = useState({
		mq: "",
		ambient: "",
		color: "",
	});

	const levelsProducts = {
		1: "https://resinempire.com/products/resina-acrilica-livello-1-4kg-proteggi-i-pavimenti-di-cemento-e-asfalto-dal-tempo-e-intemperie",
		2: "https://resinempire.com/products/resina-epossidica-livello-2-professionale-proteggi-i-pavimenti-di-officine-capannoni-casa-e-terrazzi-4kg",
	};

	const [product, setProduct] = useState({
		ambient: {},
		qty: 0,
		link: "",
	});

	const litersForBin = 4;
	const calc = () => {
		const mq = data.mq;
		const level = ambients[data.ambient]?.level;
		const hands = ambients[data.ambient]?.hands;
		let liters;
		if (hands === 1) liters = 0.13 * mq;
		else if (hands === 2) liters = 0.24 * mq;
		const bins = liters / litersForBin;
		const intPart = bins < 1 ? 1 : Number(bins.toString().split(".")[0]);
		const decPart = bins - intPart;
		let realBins;
		if (decPart <= 0.35) realBins = intPart;
		else realBins = intPart + 1;
		setProduct({
			ambient: ambients[data.ambient],
			qty: realBins,
			link: level && level.lentgh === 1 ? levelsProducts[level[0]] : "",
		});
	};

	useEffect(() => {
		calc();
	}, [data]);

	useEffect(() => {
		console.log(product);
	}, [product]);

	return (
		<div className='App'>
			<div className='form-group'>
				<label>Quanti mq misura il tuo ambiente?</label>
				<input
					className='form-control'
					type='number'
					value={Number(data.mq) ? data.mq : ""}
					onChange={e => setDataObj("mq", e.target.value)}
				/>
			</div>
			{data.mq > 0 && (
				<div className='form-group'>
					<label htmlFor=''>Seleziona il tipo di ambiente</label>
					<select
						className='form-control'
						value={data.ambient}
						onChange={e => setDataObj("ambient", e.target.value)}
					>
						<option value=''>-</option>
						{Object.keys(ambients).map(key => {
							const el = ambients[key];
							if (el.max && el.max < data.mq) return <></>;
							return <option value={key}>{el.label}</option>;
						})}
					</select>
				</div>
			)}

			{/*product.ambient?.colors === 1 && (
				<div className='form-group'>
					<label htmlFor=''>Scegli il colore</label>
					<select
						className='form-control'
						value={data.color}
						onChange={e => setDataObj("color", e.target.value)}
					>
						<option value=''>-</option>
						{Object.keys(colors).map(key => {
							const el = colors[key];
							return <option value={key}>{el}</option>;
						})}
					</select>
				</div>
			)*/}
			{product.qty > 0 && (
				<div className='product'>
					Per un suolo di {product.ambient.label}{" "}
					{product.qty === 1 ? "è necessario" : "sono necessari"}{" "}
					<strong>{product.qty} </strong>
					fust{product.qty === 1 ? "o" : "i"} di Resina di livello{" "}
					<strong>{product.ambient.level[0]}</strong> per posare{" "}
					<strong>{product.ambient.hands}</strong> man
					{product.ambient.hands === 1 ? "o" : "i"} di prodotto
					<br />
					<a
						className='bg-[#5bb85b] block p-3 rounded-md overflow-hidden mt-4 text-[#fff] font-[700]'
						target='_top'
						href={`https://resinempire.com${qs}?qty=${product.qty}&variant=${data.color}`}
					>
						VAI AL PRODOTTO
					</a>
				</div>
			)}
		</div>
	);
}

export default App;
